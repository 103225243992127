<template>
  <base-layout>
    <template>
      <details-page-title  :title="$t('components.marketPlaceManagement.buyers.details.buyerManagement.headline')" :details-id="id" />

      <details-page-header
        :name="getName"
        :badge="getBadge"
        :avatar="getAvatar"
        :email="getEmail"
        :tabs="tabs"
      />

      <div class="min-h-screen">
        <router-view />
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '@/components/ui/DetailsPageHeader.vue'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
export default {
  name: 'ViewBuyerDetails',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
  },
  async created() {
    this.$http
      .get(MarketplaceConfig.api.marketplaceBuyerProfile(this.id))
      .then((res) => (this.header = res.data.data))
  },

  data() {
    return {
      header: {},
      symbol: '$',

      fallbackText: '--',

      tabs: [
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.tabs.profile'), href: this.getTabHref(`profile`) },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.tabs.orders'), href: this.getTabHref(`orders`) },
      ],
    }
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    getName() {
      return this.header.user
        ? `${this.header.user.first_name} ${this.header.user.last_name}`
        : ''
    },
    getBadge() {
      return 'Buyer'
    },

    getAvatar() {
      return ''
    },
    getEmail() {
      return this.header.user ? this.header.user.email : ''
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/marketplaces/buyers/${this.$route.params.id}/${path}`
    },
  },
}
</script>
